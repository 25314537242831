<template>
  <div>
    <div class="coupon left vcenter">
      <md-input-container class="w100">
        <md-input v-model="couponModel"></md-input>
      </md-input-container>
      <md-button type="button" class="md-icon-button" v-if="!couponCode" :disabled="!!couponCode" @click="applyCoupon(couponModel)">
        <md-icon>done</md-icon>
      </md-button>
      <md-button @click="removeCoupon()" v-else style="width:120px">
        {{couponCode}}
        <md-icon>close</md-icon>
      </md-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["amount"],
  data() {
    return {
      coupon: null,
      couponCode: null,
      couponModel: "A100",
      totalAmount: 0,
      totalAmountNoCoupon: 0
    };
  },
  computed: {},
  created() {
    this.totalAmountNoCoupon = this.amount;
  },
  methods: {
    async applyCoupon(code) {
      this.couponCode = null; // To prevent same code being applied twice
      try {
        let data = await this.$store.dispatch("crud/get", {
          api: "coupons",
          params: {
            where: {
              code: code,
              active: true,
              minimumCartValue: { $lte: this.totalAmountNoCoupon }
            }
          }
        });
        if (data.length === 0) {
          this.err("Coupon is not active");
          return;
        }
        if (data[0].amount >= this.amount) {
          this.err("Please add some more items before applying this coupon");
          return;
        } else {
          this.coupon = data[0];
          this.couponCode = data[0].code + " (" + data[0].amount + ")";
          // this.totalAmount -= parseInt(data[0].amount)

          this.$emit("selected", data[0].amount);
        }
      } catch (err) {
        this.err(err);
      }
    },
    removeCoupon() {
      this.coupon = this.couponCode = undefined;
      this.amount = this.totalAmountNoCoupon;

      this.$emit("selected", 0);
    },
    err(err) {
      this.$emit("err", err);
    }
  }
};
</script>
<style lang="less">
.coupon {
  .w100 {
    max-width: 120px;
  }
}
</style>
