<template>
    <div class="address-my">
        <md-card :class="{ selected: selected == address._id }" :key="ix" @click.native="selectAddress(address)" class="address-card" v-for="(address, ix) in addresses">
            <div class="selected-tip">
                <md-icon v-if="selected == address._id">check_circle</md-icon>
                <span>Delivery Address</span>
            </div>
            <md-card-content>
                <h3>{{ user.name | titlecase }}</h3>
                <p>{{ user.email }}</p>
                <p>{{ address.phone }}</p>
                <p>{{ address.address }}</p>
                <p>{{ address.apartNo }}</p>
                <p>{{ address.city }}, {{ address.state }}, {{ address.zip }}</p>
                <p>{{ address.country }}</p>
            </md-card-content>
            <md-card-actions>
                <router-link :to="'/admin/addresses/' + address._id + '?referrer=/checkout'" class="md-icon-button" tag="md-button">
                    <md-icon>edit</md-icon>
                </router-link>
                <md-button @click="removeAddress(address)" class="md-icon-button">
                    <md-icon>delete</md-icon>
                </md-button>
            </md-card-actions>
        </md-card>
        <router-link class="md-raised md-primary" tag="md-button" to="/admin/addresses/new?referrer=/checkout"> <md-icon>add</md-icon>New Address </router-link>

        <confirm @close="removeConfirmed" ref="deleteConfirm"></confirm>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import Confirm from "@/components/modal/Confirm";
import { addressFields } from "@/config";
import { mapActions, mapGetters } from "vuex";
export default {
    props: {},
    components: {
        Edit,
        Confirm,
    },
    data() {
        return {
            selected: null,
            addresses: [],
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
        }),
        addressFields() {
            return addressFields;
        },
    },
    methods: {
        async loadMyAddress() {
            let result = await this.$store.dispatch("crud/get", {
                api: "addresses/my",
            });
            this.addresses = result.data;

            if (this.addresses.length) {
                this.selectAddress(this.addresses[0]);
            }
        },
        selectAddress(a) {
            console.info("select address", a);
            this.address = a;
            this.selected = a && a._id;
            this.$emit("selected", a);
        },
        removeAddress(item) {
            this.$refs.deleteConfirm.open("Would you like to delete the address ?", null, item);
        },
        async removeConfirmed(type, item) {
            await this.$store.dispatch("crud/delete", { api: "addresses/" + item._id });
            this.loadMyAddress();
        },
    },
    created() {
        this.loadMyAddress();
    },
};
</script>
<style lang="less">
.address-my {
    min-width: 300px;
    .md-card {
        position: relative;
        cursor: pointer;
        &.selected {
            box-shadow: 0 1px 5px #ffa726, 0 2px 2px #ffa726, 0 3px 1px -2px #ffa726;
        }

        .selected-tip {
            position: absolute;
            top: 10px;
            width: 100%;
            text-align: center;
            color: #ffa726;
        }

        .md-card-content {
            padding-bottom: 0;
        }
        p {
            margin: 0;
        }
    }
}
</style>