<template>
    <div class="checkout">
        <div class="checkout-details">
            <template v-for="(order, oix) in orders">
                <md-card :key="oix" v-if="isVendorOrder(order)">
                    <md-card-content>
                        <h3>{{ order.vendor_name }}</h3>
                        <md-table>
                            <md-table-body>
                                <md-table-row :key="ix" class="item-row" v-for="(item, ix) in order.items">
                                    <md-table-cell class="shop-detail-cell">
                                        <div class="shop-detail">
                                            <div class="logo">
                                                <img :src="item.image | googleimage" alt />
                                            </div>
                                            <div class="name">
                                                <router-link :to="`/${item.slug}/${item._id}`">{{ item.name }} {{ item.vname && "/" }} {{ item.vname }}</router-link>
                                                <template v-for="(addOn, ixx) in item.addOnItems">
                                                    <div :key="ixx" v-if="item && item.addOnItems && addOn.quantity">{{ addOn.name }} * {{ addOn.quantity }}</div>
                                                </template>
                                            </div>
                                        </div>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <div class="price" v-if="item.rental">
                                            {{ item.quantity }} @ {{ item.price | currency }} * {{ getRentalPeriod({ _id: item._id, vid: item.vid }) }} {{ item.rentalInterval | intervalName }} + ${{
                                                getAddOnItemsPrice({ _id: item._id })
                                            }}= {{ ((item.price * getRentalPeriod({ _id: item._id, vid: item.vid }) + getAddOnItemsPrice({ _id: item._id })) * item.quantity) | currency }}
                                        </div>
                                        <div class="price" v-if="!item.rental">
                                            {{ item.quantity }} @ {{ item.price | currency }} + ${{ getAddOnItemsPrice({ _id: item._id }) }} =
                                            {{ ((item.price + getAddOnItemsPrice({ _id: item._id })) * item.quantity) | currency }}
                                        </div>
                                        <div class="period" v-if="item.rental">
                                            <small>{{ item.rentalStartDate | date }} - {{ item.rentalEndDate | date }}</small>
                                        </div>
                                        <div class="period" v-if="!item.rental">
                                            <small>{{ item.rentalStartDate | date }}</small>
                                        </div>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell>Booking Total:</md-table-cell>
                                    <md-table-cell>
                                        <span>{{ order.bookingTotal | currency }}</span>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell>Booking Deposit:</md-table-cell>
                                    <md-table-cell>
                                        <span>{{ order.bookingDeposit | currency }}</span>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell>Delivery:</md-table-cell>
                                    <md-table-cell>
                                        <div>
                                            <span>{{ order.shipping.charge | currency }}</span>
                                            <small>({{ order.shipping.name }})</small>
                                        </div>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="order.offhour.charge">
                                    <md-table-cell>Off-Hour Service Fee:</md-table-cell>
                                    <md-table-cell>
                                        <div>
                                            <span>{{ order.offhour.charge | currency }}</span>
                                            <!-- <small>({{ order.shipping.name }})</small> -->
                                        </div>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell>Security Deposit:</md-table-cell>
                                    <md-table-cell>
                                        <span>{{ order.securityDeposit | currency }}</span>
                                        <small>(removed pending inspection of items)</small>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell>Remaining Balance:</md-table-cell>
                                    <md-table-cell>
                                        <span style="color: red;">{{ order.remaining | currency }}</span>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell>Billed Today:</md-table-cell>
                                    <md-table-cell>
                                        <b>{{ order.billedToday | currency }}</b>
                                    </md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="showPickup(order)">
                                    <md-table-cell>
                                        <md-checkbox v-model="pickups[order.vendor_name]">Customer Pickup</md-checkbox>
                                    </md-table-cell>
                                    <md-table-cell>
                                        <template v-if="order.vendor && pickups[order.vendor_name]">
                                            <p>{{ order.vendor.business }}</p>
                                            <p>{{ order.vendor.address }}</p>
                                            <p>{{ order.vendor.city }},{{ order.vendor.state }},{{ order.vendor.zip }}</p>
                                            <p>{{ order.vendor.phone }}</p>
                                            <p>{{ order.vendor.email }}</p>
                                        </template>
                                    </md-table-cell>
                                </md-table-row>
                            </md-table-body>
                        </md-table>
                    </md-card-content>
                </md-card>
            </template>

            <md-card class="checkout-card" md-align="start">
                <md-card-content>
                    <md-table>
                        <md-table-body>
                            <md-table-row v-if="!vendorMode">
                                <md-table-cell>Total Billed Today:</md-table-cell>
                                <md-table-cell>
                                    <b>{{ totalBilledToday | currency }}</b>
                                </md-table-cell>
                            </md-table-row>
                            <md-table-row>
                                <md-table-cell colspan="2">
                                    <md-checkbox v-model="isAgree">
                                        I have read and agreed to the
                                        <router-link to="/terms-of-service">Terms of Service</router-link>
                                    </md-checkbox>
                                </md-table-cell>
                            </md-table-row>
                            <md-table-row v-if="showStripeWay && showTilledWay">
                                <md-table-cell>
                                    Gate Way:
                                </md-table-cell>
                                <md-table-cell>
                                    <div>
                                        <md-radio v-if="showStripeWay" v-model="gateway" md-value="stripe" class="md-primary" style="margin-right: 24px;">Stripe</md-radio>
                                        <md-radio v-if="showTilledWay" v-model="gateway" md-value="EzTimePay" class="md-primary">EzTimePay</md-radio>
                                    </div>
                                </md-table-cell>
                            </md-table-row>
                        </md-table-body>
                    </md-table>
                </md-card-content>
                <md-card-actions class="sa">
                    <md-button :disabled="loading" @click="checkoutNow" class="md-primary md-raised"> <md-icon>payment</md-icon>Place Order </md-button>
                </md-card-actions>
            </md-card>
        </div>
        <div>
            <address-my @selected="selectedAddress"></address-my>
        </div>
        <loading v-if="loading"></loading>
        <pay-confirm :amount="totalBilledToday" :type="gateway" @confirm="payConfirm" ref="payConfirm"></pay-confirm>
    </div>
</template>
<script>
import { country, states } from "@/config";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import Loading from "@/components/Loading";
import Coupon from "@/components/Coupon/Coupon";
import Toast from "@/components/Toast";
import AddressMy from "@/components/Address/AddressMy";
import PayConfirm from "@/components/PayConfirm";
import moment from "moment-timezone";

const countries = { [country.name]: country.code };

export default {
    components: {
        Loading,
        AddressMy,
        Coupon,
        Toast,
        PayConfirm
    },
    data() {
        return {
            ready: false,
            loading: false,
            securityDeposit: 0,
            orders: [],
            address: null,
            isAgree: false,
            gateway: "",
            pickups: {} // {[vendor_name]:boolean}
        };
    },
    computed: {
        ...mapGetters({
            cartItems: "cart/items",
            groupItems: "cart/groupItems",
            getRentalPeriod: "cart/getRentalPeriod",
            getQuantity: "cart/getQuantity",
            getAddOnItemsPrice: "cart/getAddOnItemsPrice",
            paymentSetting: "setting/payment",
            vendorMode: "vendorMode",
            affiliateId: "affiliateId"
        }),
        showTilledWay() {
            return this.paymentSetting.value && this.paymentSetting.value.use_tilled;
        },
        showStripeWay() {
            return this.paymentSetting.value && this.paymentSetting.value.use_stripe;
        },
        totalBilledToday() {
            let total = this.orders.reduce((acc, order) => order.billedToday + acc, 0);
            return Math.round(total * 100) / 100;
        },
        isVendorOrder() {
            return order => !this.vendorMode || !this.vendorMode.name || order.vendor_name == this.vendorMode.name;
        },
        showPickup() {
            return order => !order.items.find(i => !i.pickup);
        },

        isOffHour() {
            return order =>
                order.items.find(i => {
                    if (i.rental) {
                        let deliveryTime = +moment(i.rentalStartDate).format("HHmmss");
                        if (deliveryTime < 90000 || deliveryTime > 210000) {
                            return true;
                        }

                        let pickupTime = +moment(i.rentalEndDate).format("HHmmss");
                        if (pickupTime < 90000 || pickupTime > 210000) {
                            return true;
                        }
                    }

                    if (!i.rental) {
                        let deliveryTime = +moment(i.rentalStartDate).format("HHmmss");
                        if (deliveryTime < 90000 || deliveryTime > 210000) {
                            return true;
                        }
                    }
                });
        }
    },
    watch: {
        showStripeWay: {
            immediate: true,
            handler() {
                if (this.showStripeWay) {
                    this.gateway = "stripe";
                } else {
                    this.gateway = "EzTimePay";
                }
            }
        },
        cartItems: {
            deep: true,
            handler() {
                console.info("cart changed");
                this.ready && this.init();
            }
        },
        pickups: {
            deep: true,
            handler() {
                this.init();
            }
        }
    },
    methods: {
        ...mapActions({
            checkout: "cart/checkout"
        }),
        async init() {
            console.info("init");
            await this.loadSecurityDeposit();
            this.orders = [];
            for (let i in this.groupItems) {
                let items = this.groupItems[i];
                let vendor_name = i;
                let bookingTotal = 0;
                let shippingTotal = 0;
                let bookingDeposit = 0;
                let bookingToday = 0;
                let securityDeposit = 0;
                let vendor_id = "";
                for (let it in items) {
                    let item = items[it];
                    let { addOnItems = [], rental, rentalDeposit, quantity, price, _id, vid } = item;
                    let rentalPeriod = this.getRentalPeriod({ _id, vid });
                    let subTotal = rental ? quantity * price * rentalPeriod : quantity * price;
                    let addOnItemsPrice = addOnItems.reduce((acc, addOnItem) => {
                        const { active, quantity, price } = addOnItem;
                        if (active && quantity) {
                            acc += price * quantity * item.quantity;
                        }
                        return acc;
                    }, 0);
                    vendor_id = item.vendor_id;
                    subTotal += addOnItemsPrice;
                    let subDeposit = rental ? subTotal * rentalDeposit : 0;
                    securityDeposit = rental ? this.securityDeposit : securityDeposit;
                    bookingTotal += subTotal;
                    bookingDeposit += subDeposit;
                    bookingToday = rental ? bookingToday + subDeposit : bookingToday + subTotal;
                    shippingTotal += subTotal;
                }
                bookingTotal = Math.round(bookingTotal * 100) / 100;
                shippingTotal = Math.round(shippingTotal * 100) / 100;

                let shipping = { charge: 0, name: "pickup" };
                if (!this.pickups[vendor_name] && this.address) {
                    // let originAddress = {};
                    let destAddress = this.address;
                    shipping = await this.loadDistanceFee(vendor_id, destAddress);
                }

                let offhour = { charge: 0 };
                if (this.isOffHour({ items })) {
                    offhour = await this.loadOffHourFee(vendor_id);
                }

                let total = Math.round((bookingTotal + shipping.charge + offhour.charge + securityDeposit) * 100) / 100;
                let billedToday = Math.round((bookingToday + shipping.charge + offhour.charge) * 100) / 100;
                let remaining = Math.round((total - billedToday) * 100) / 100;

                let vendor = await this.getVendor(vendor_id);
                console.info(vendor);

                this.orders.push({ vendor, vendor_name, vendor_id, items, bookingTotal, bookingDeposit, shipping, offhour, securityDeposit, total, billedToday, remaining });
            }
            console.info("orders", this.orders);
        },
        async loadSecurityDeposit() {
            let result = await this.$store.dispatch("crud/get", {
                api: "orders/securityDeposit"
            });
            this.securityDeposit = result.securityDeposit;
        },
        async loadDistanceFee(vendor_id, destAddress) {
            let result = await this.$store.dispatch("crud/post", {
                api: "shippings/distance-fee",
                data: {
                    vendor_id,
                    destAddress
                }
            });
            return result;
        },
        async loadOffHourFee(vendor_id) {
            let result = await this.$store.dispatch("crud/post", {
                api: "shippings/offhour-fee",
                data: {
                    vendor_id
                }
            });
            return result;
        },

        async getVendor(vendor_id) {
            var result = await this.$store.dispatch("crud/get", {
                api: `users/vendor/${vendor_id}`
            });

            console.info(result.vendor);
            return result.vendor;
        },
        selectedAddress(address) {
            this.$set(this, "address", address);
            this.init();
        },
        checkoutNow() {
            if (this.cartItems.length == 0) {
                this.$store.commit("setMessage", "Your cart found empty. Please add some items");
                return;
            }
            if (!this.address || !this.address.phone) {
                this.$store.commit("setMessage", "You should enter an address with phone number");
                return;
            }
            if (!this.isAgree) {
                this.$store.commit("setMessage", "You must agree the terms of service to continue");
                return;
            }
            this.$refs["payConfirm"].open();
        },
        payConfirm({ card }) {
            this.$store.commit("setLoading", true);
            this.checkout({ address: this.address, pay: true, card: card, pickups: this.pickups, gateway: this.gateway });
        }
    },
    async created() {
        await this.$store.dispatch("setting/refreshPayment");
    },
    async mounted() {
        // await this.init();
        this.ready = true;
    }
};
</script>
<style lang="less">
.checkout {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    margin: auto;

    .item-row {
        .md-table-cell:nth-child(1) {
            .md-table-cell-container {
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    .shop-detail {
        display: flex;
        max-width: 200px;
        .logo {
            width: 40px;
            height: 40px;
            overflow: hidden;
            margin-right: 7px;
            img {
                width: 100%;
            }
        }
        .name {
            flex: 1;
            max-width: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > * {
                white-space: initial;
            }
        }
    }
    .period {
        color: #aaa;
    }
    .checkout-details {
        max-width: 100%;
        flex: 1;
        .md-card-actions {
            justify-content: center !important;
        }
        .md-table .md-table-cell:nth-child(1) {
            width: 180px;
        }
    }
}
@media (max-width: 600px) {
    .checkout {
        .address-my {
            width: 100%;
        }
    }
}
</style>
